<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="">
          <v-alert
            dense
            border="left"
            type="warning"
            dismissible
            v-if="serverData.location_movement_task.show_alert"
          >
            <span
              >New avelable quantity:
              {{ serverData.location_movement_task.new_available_qty }}</span
            >
          </v-alert>
        </div>
        <!-- <div class="bg-secondary rounded p-6 mb-3">
          <v-row>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  SKU:
                </v-col>
                <v-col class="py-0">{{
                  serverData.location_movement_task.sku_component.text1
                }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Description:
                </v-col>
                <v-col>{{
                  serverData.location_movement_task.sku_component.text2
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div> -->

        <div class="row pa-0 ma-0">
          <div
            class="col-12 col-sm-6 my-0 py-0 h-60px"
            :class="{ 'col-lg-6 col-md-6': isClient }"
          >
            <v-text-field
              v-model="serverData.location_movement_task.warehouse"
              label="Warehouse"
              disabled
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px" v-if="!isClient">
            <v-text-field
              v-model="serverData.location_movement_task.customer"
              label="Customer"
              clearable
              outlined
              disabled
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.from_location"
              label="From location"
              clearable
              disabled
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.to_location"
              label="To location"
              clearable
              disabled
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="h-75px col-12 col-sm-6 my-0 py-0 h-60px">
            <v-combobox
              :readonly="isSkuFieldEditable"
              v-model="serverData.location_movement_task.sku_component.text1"
              dense
              disabled
              label="SKU"
              clearable
              outlined
              hide-no-data
              hide-selected
            ></v-combobox>
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="serverData.location_movement_task.sku_component.text2"
              dense
              disabled
              label="SKU description"
              clearable
              outlined
              hide-no-data
              hide-selected
            ></v-text-field>
          </div>
          <div
            class="col-12 col-sm-6 my-0 py-0 h-60px"
            :class="{ 'col-lg-6 col-md-6': isClient }"
          >
            <v-text-field
              v-model="formData.batch_number"
              label="Batch number"
              clearable
              disabled
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.available_qty"
              label="Available quantity"
              min="0"
              type="number"
              @change="() => validateMinValue('available_qty', 0, 'formData')"
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.moved_qty"
              label="Moved quantity"
              min="1"
              type="number"
              @change="() => validateMinValue('moved_qty', 0, 'formData')"
              :disabled="type == 'complete'"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="this.resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ type === "approve" ? "Approve" : "Complete" }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader", "type"],
  mixins: [fieldValueValidation],
  components: {},

  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,

    serverData: null,
    formData: {
      id: null,
      warehouse_id: null,
      customer_id: null,
      from_location: null,
      to_location: null,
      batch_number: null,
      sku_id: null,

      inventory_balance_id: null,
      available_qty: null,
      moved_qty: null,
    },
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      const data = { id: this.item.id };
      ApiService.post("/warehouse_management/location_movements/show", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoader(false);
        });
    },

    submitComplete() {
      let data = {
        id: this.formData.id,
      };
      this.pageLoader(true);
      ApiService.post("/warehouse_management/location_movements/complete", data)
        .then(() => {
          Swal.fire({
            title: "Completed",
            text: ``,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    submitAprove() {
      let data = {
        id: this.formData.id,
        available_qty: this.formData.show_alert
          ? this.formData.new_available_qty
          : this.formData.available_qty,
        moved_qty: this.formData.moved_qty,
      };
      this.pageLoader(true);
      ApiService.post("/warehouse_management/location_movements/approve", data)
        .then(() => {
          Swal.fire({
            title: "Approved",
            text: ``,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      if (this.type == "approve") {
        this.submitAprove();
      } else {
        this.submitComplete();
      }
    },
    resetEditForm() {
      if (this.serverData) {
        let copy = { ...this.serverData.location_movement_task };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.location_movement_task).forEach(
            (serverD) => {
              if (localD[0] === serverD[0]) {
                this.formData[localD[0]] = copy[serverD[0]];
              }
            }
          );
        });
        this.formData.sku_id = {
          skuId: this.serverData.location_movement_task.sku_id,
          systemCode: this.serverData.location_movement_task.sku_barcode,
        };
      } else {
        this.formData = {
          id: null,
          warehouse_id: null,
          customer_id: null,
          from_location: null,
          to_location: null,
          batch_number: null,
          sku_id: null,

          inventory_balance_id: null,
          available_qty: null,
          moved_qty: null,
        };
      }
    },

    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    item() {
      return this.$store.getters.getItemForAction;
    },
    isSkuFieldEditable() {
      return !this.isClient && !this.formData.customer_id;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    userId: function () {
      return this.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.customer_id;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    items: function () {
      return this.entries;
    },
  },
  watch: {},
};
</script>
